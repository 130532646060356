<template>
<BlockUI :blocked="loading">
     <div class="card mt-2" style="margin-bottom: 2em">
       <h5>Summary</h5>
       <hr>
        <table class="mt-2 text-lg">
            <thead>
                <tr>
                    <th>Avg outlets per beat</th>
                    <th>Avg sales per beat</th>
                    <th>Avg working hours per beat</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>{{ formatNumber(data?.length > 0 ? data[0].avg_total_outlet : 0) }}</td>
                    <td>{{ formatNumber(data?.length > 0 ? data[0].avg_total_sales : 0) }}</td>
                    <td>{{ formatNumber(data?.length > 0 ? data[0].avg_total_working_hours : 0) }}</td>
                </tr>
            </tbody>
        </table>
    </div>
</BlockUI>
</template>

<script>
export default {
    props: [
		"filters",
        "filterButton",
        "loadingFilter",
        "item"
    ],
    data() {
        return {
            loading: false,
            data: null
        }
    },
    mounted() {
        this.getData();
    },
    watch: {
        filterButton: {
            handler() {
                setTimeout(() => {
                    this.getData();
                }, 200);
            },
        }
    },
    methods:{
        formatNumber(value) {
            if (isNaN(value)) return "0.00";
            return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(value);
        },
        getData(){

            this.loading=true;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/trans-outlet-beat/summary',
                params: {
                    "cluster_id": this.item.trans_cluster.cluster_id,
                    "clusterbeat_id": this.filters.clusterbeat_id,
                }
            })
            .then(res => {
                this.data = res.data.data;

                this.loading= false;
            })
            .catch((err) => {
                this.loading= false;
                console.log(err);
            });
        },
    }
    
}
</script>

<style>
    table {
        width: 100%;
        border-collapse: collapse;
    }
    th, td {
        border: 1px solid #ddd;
        padding: 8px;
    }
    th {
        background-color: #f2f2f2;
        text-align: left;
    }
</style>