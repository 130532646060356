<template>
  <Breadcrumb :home="home" :model="items" class="mb-3"/>
  <div class="grid">
		<div class="col-12">
            <div class="card">
                <Toast/>
                <Error :errors="errors"/>

                <Fieldset legend="Add New" :toggleable="true" :collapsed="true" class="mb-3">
                    <Create @submit="getDataTable()" ref="create"/>
                </Fieldset>

                <TagSlotComponent :total="user?.userweb_status?.total_data_industry" :max="user?.userweb_status?.max_industries" /> 

                <DataTable :value="dataTable" :resizableColumns="true" columnResizeMode="fit" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')"/>
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')"/>
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Search..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column field="number" header="#" :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="industry_name" header="Industry Name">
                        <template #body="slotProps">
                            {{slotProps.data.industry_name}}
                        </template>
                    </Column>
					<Column header="Action">
						<template #body="slotProps">
							<Button icon="pi pi-pencil" class="p-button-rounded p-button-success mr-2" @click="editItem(slotProps.data)" :disabled="slotProps.data.can_delete == 'n'"/>
							<Button icon="pi pi-trash" class="p-button-rounded p-button-danger mr-2" @click="deleteItem(slotProps.data)" :disabled="slotProps.data.can_delete == 'n'"/>
						</template>
					</Column>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Edit @submit="getDataTable()" :item="item" ref="edit"/>

                <Delete @submit="getDataTable()" :item="item" ref="delete"/>
            </div>
      </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../utils/helpers';
import Create from '../../components/master/industry/Create.vue';
import Edit from '../../components/master/industry/Edit.vue';
import Delete from '../../components/master/industry/Delete.vue';
import Error from '../../components/Error.vue';
import TagSlotComponent from '../../components/TagSlotComponent.vue';

export default {
    components: {
		'Create': Create,
		'Edit': Edit,
		'Delete': Delete,
		'Error': Error,
		'TagSlotComponent': TagSlotComponent,
	},
    data() {
        return {
            // breadcrumb
            home: {icon: 'pi pi-home', to: '/'},
            items: [
                {label: 'Industry'},
            ],

            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            // edit,delete
            item: {},
            
            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,
        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        },
    },
    computed:{
        ...mapGetters(['errors']),

		...mapGetters("auth", ["user"]),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        // EDIT
		editItem(item) {
			this.item = item;
            
            this.$refs.edit.editItemDialog = true;
		},
        // DELETE
		deleteItem(item) {
			this.item = item;
            
            this.$refs.delete.deleteItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            // this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/master-industry',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
                // this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                // this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
            method: 'GET',
            url: process.env.VUE_APP_ROOT_API + 'web/master-industry/export',
            responseType: 'blob',
            params: {
                "ext" : ext,
            }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Industry.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
