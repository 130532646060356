<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <!-- <img src="/favicon.png" alt="Web Report" class="mb-5" style="width:81px;"> -->
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(48, 149, 247, 0.4) 10%, rgba(48, 149, 247, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center bg-blue-500 border-circle" style="width:3.2rem; height:3.2rem;">
                            <i class="pi pi-fw pi-spin pi-spinner text-2xl text-50"></i>
                        </div>
                        <h1 class="text-blue-900 font-bold text-4xl lg:text-5xl mb-2">Redirect Dashboard</h1>
                        <ProgressBar mode="indeterminate" style="height: .5em; background-color: rgba(48, 149, 247, 0.3);" />
                        <!-- <span class="text-600 text-center">You do not have the necesary permisions. Please contact admins.</span>
                        <img src="images/asset-access.svg" alt="Access denied" class="mt-5" width="80%"> -->
                        <!-- <div class="col-12 mt-5 text-center">
                            <i class="pi pi-fw pi-arrow-left text-blue-500 mr-2" style="vertical-align:center;"></i>
                            <router-link to="/" class="text-blue-500">Go to Dashboard</router-link>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        const token = this.$route.query.token;
        if(token){
            this.checkToken(token);
        }
    },
    methods: {
        checkToken(token) {

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'check-token',
                data: {
                    "token": token,
                }
            })
            .then(res => {

                localStorage.setItem("authToken", res.data.data.token);

                if (res) {
                    this.$router.replace({ name: "dashboard" })
                    this.$store.commit('setErrors', {});
                }
            }, (this))
            .catch((err) => {
                this.$router.replace({ name: "login" })
                
                this.$store.commit('setErrors', err);
            });
        }
    }
}
</script>