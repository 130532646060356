<template>
	<div class="grid">
		<div class="col-12 lg:col-12 xl:col-12">
			<Message severity="info">
				<h3>Package Details: <u> {{user?.userweb_status?.package_name}} Plan</u></h3>
				<p>
					You have selected the {{user?.userweb_status?.package_name}} Plan, which includes the following features and slots:
				</p>
				<ul>
					<li><strong>Project Creation Slots:</strong> {{user?.userweb_status?.max_project}} projects</li>
					<li><strong>Industry Creation Slots:</strong> {{user?.userweb_status?.max_industries}} industries</li>
					<li><strong>Cluster Creation Slots:</strong> {{user?.userweb_status?.max_slot_cluster}} cluster</li>
					<li><strong>Maximal Outlet Distance:</strong> {{user?.userweb_status?.max_outlet_distance}} m</li>
				</ul>
				<p>
					<strong>Subscription Start Date:</strong> {{formatDate(user?.userweb_status?.package_start)}} <br>
					<strong>Subscription Expiry Date:</strong> {{formatDate(user?.userweb_status?.package_end)}} <br>
					<!-- <strong>Days Left in Subscription:</strong> {{ calculateDaysLeft() }} days -->
				</p>
				<p>
					To maximize your subscription benefits, ensure you use these slots effectively. 
					For further details or to upgrade your plan, visit <u><a @click="payment" style="cursor: pointer;"> the payment page.</a></u>
					  
				</p>
			</Message>
		</div>
	</div>

</template>

<script>

import moment from 'moment';
import { mapGetters } from "vuex";

export default {
	computed: {
		...mapGetters("auth", ["user"]),
	},
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        payment(){

            this.axios({
				method: 'GET',
				url: process.env.VUE_APP_ROOT_API + 'user-token',
				params: {

				}
            })
            .then(response => {
                let paymentUrl = response.data.data.url;
				window.open(paymentUrl, '_blank');

            })
            .catch((err) => {
                console.log(err);
            });
        },
        calculateDaysLeft() {
            const today = new Date();
            const endDate = new Date(this.user?.userweb_status?.package_end);
            const diffTime = endDate - today;
            return Math.max(0, Math.ceil(diffTime / (1000 * 60 * 60 * 24)));
        }
    },
}
</script>