<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <!-- <img src="/favicon.png" alt="Web Report" class="mb-5" style="width:81px;"> -->
            </div>
            <div class="col-12 xl:col-6" style="border-radius:56px; padding:0.3rem; background: linear-gradient(180deg, rgba(247, 149, 48, 0.4) 10%, rgba(247, 149, 48, 0) 30%);">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0));">
                    <div class="grid flex flex-column align-items-center">
                        <div class="flex justify-content-center align-items-center bg-orange-500 border-circle" style="width:3.2rem; height:3.2rem;">
                            <!-- <i class="pi pi-fw pi-credit-card text-2xl text-50"></i> -->
                            <p class="text-2xl text-50">:(</p>
                        </div>
                        <h1 class="text-900 font-bold text-4xl lg:text-5xl mb-2">Your subscription has expired</h1>
                        <span class="text-600 text-center">Please renew your package to continue using the service.</span>
                        <div class="col-12 mt-5 text-center">
                            <i class="pi pi-fw pi-external-link mr-2" style="vertical-align:center;"></i>
                            <a @click="payment" style="cursor: pointer;">Renew Subscription</a>
                        </div>
                        <div class="col-12 mt-5 text-center">
                            <a @click="logout" class="p-link layout-topbar-button">
                                <i class="pi pi-sign-out mr-2"></i>
                                <span>Logout</span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import { mapActions } from "vuex";

export default {
    methods: {
        ...mapActions("auth", ["sendLogoutRequest"]),

        logout() {
            this.sendLogoutRequest();
        },
        
        payment() {
            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'user-token',
                params: {}
            })
            .then(response => {
                let paymentUrl = response.data.data.url;
                window.open(paymentUrl, '_blank');
            })
            .catch((err) => {
                console.log(err);
            });
        },
    },
}
</script>
